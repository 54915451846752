import { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
    name: 'default',
    palette: {
        common: {
            black: '#0B0B0B',
            white: '#fff',
            offWhite: '#fafafa',
            darkBlue: '#0A2841',
            lightCyan: '#C2D1D3',
        },
        error: {
            main: '#D64469',
        },
        primary: {
            main: '#00ADEF',
        },
        success: {
            main: '#00D100',
        },
        grey: {
            main: '#F1F1F2',
            medium: '#E2E2E4',
        },
        text: {
            disabled: '#AEAEAF',
            hint: '#688488',
            primary: '#0B0B0B',
            secondary: '#16181A',
            label: '#231F20',
            placeholder: '#AEAEAF',
            light: '#FFFFFF',
        },
        background: {
            default: '#F7F7F8',
        },
    },
    shadows: {
        small: '0 2px 10px 0 rgba(0, 0, 0, 0.05)',
        large: '0 2px 10px 0 rgba(0, 0, 0, 0.1)',
    },
    shape: {
        rounded: {
            borderRadius: 20,
            borderRadiusSm: 14,
        },
    },
    typography: {
        htmlFontSize: 16,
        fontFamily: "'Avenir Next W01'",
        fontSize: 14,
        fontWeight: {
            light: 300,
            regular: 400,
            medium: 500,
            bold: 700,
        },
        small: {
            fontSize: 10,
            fontWeight: 'regular',
            letterSpacing: 0,
            lineHeight: 23,
        },
        inputLabel: {
            fontSize: 13,
            fontWeight: 600,
            letterSpacing: 0.3,
            lineHeight: 18,
        },
        inputSubLabel: {
            fontSize: 10,
            fontWeight: 600,
            letterSpacing: 0.5,
            lineHeight: 10,
        },
        inputValue: {
            fontSize: 13,
            fontWeight: 'medium',
            letterSpacing: 0.3,
            lineHeight: 18,
        },
        inputPlaceholder: {
            fontSize: 15,
            fontWeight: 'regular',
            letterSpacing: 0,
            lineHeight: 20,
        },
    },
    iconography: {
        default: {
            stroke: '#000',
        },
        light: {
            stroke: '#fff',
        },
        primary: {
            stroke: '#00ADEF',
        },
        defaultCircular: {
            stroke: '#000',
            background: '#fff',
        },
        success: {
            stroke: '#00D100',
        },
        danger: {
            stroke: '#D64469',
        },
        lightGray: {
            stroke: '#688488',
        },
        lightCircular: {
            stroke: '#fff',
            background: '#000',
        },
        primaryCircular: {
            stroke: '#00ADEF',
            background: '#fff',
        },
        successCircular: {
            stroke: '#00D100',
            background: '#fff',
        },
        dangerCircular: {
            stroke: '#D64469',
            background: '#fff',
        },
    },
};
