import { hesselViewModels } from '../../../view-models';

/**
 * For the selected color: If a matching resource was found, use it.
 * @param selectedProduct
 * @param selectedColor
 * @returns
 */
export function handleCarResources(
    selectedProduct: hesselViewModels.ProductDetails,
    selectedColor?: hesselViewModels.ProductColor,
    matchedResources?: Array<hesselViewModels.PdpResource>
): { resources: Array<hesselViewModels.PdpResource>; matchFound: boolean } {
    if (!selectedColor) {
        return {
            resources: selectedProduct.resources,
            matchFound: false,
        };
    }

    const matchingResource = selectedProduct.resources.filter((x) => x.type === 'image' && x.colorCode === selectedColor.code);
    const youTubeVideos = selectedProduct.resources.filter((x) => x.type === 'youTube');
    const vehicleVideos = selectedProduct.vehicleType === 'Van' ? selectedProduct.resources.filter((x) => x.type === 'video') : [];

    if (matchingResource.length) {
        const interior = selectedProduct.resources.filter((x) => x.type === 'image' && x.colorCode === 'Interior');
        return {
            resources: [...matchingResource, ...interior, ...youTubeVideos, ...vehicleVideos],
            matchFound: true,
        };
    }

    // If no resources found, take the first one.
    const imageResource = selectedProduct.resources.find((x) => x.type === 'image');
    const firstAvailableColorCode = imageResource && imageResource.type === 'image' ? imageResource.colorCode : undefined;
    const firstAvailableResource = selectedProduct.resources.filter((x) => x.type === 'image' && x.colorCode === firstAvailableColorCode);

    return {
        resources: matchedResources ?? firstAvailableResource,
        matchFound: false,
    };
}
