import { StateMapper, ActionTypes } from 'easy-peasy';
import { handleCarResources } from './car-resources.helper';
import { getAvailableConfigurations } from './configuration.helper';
import { ProductDetailsStoreModel } from './product-details.types';
import { _FilterKeys } from 'ts-toolbelt/out/Object/FilterKeys';
import { _Pick } from 'ts-toolbelt/out/Object/Pick';
import { validateOwnershipModeAndSelectedTab } from './selected-tab.helper';
import { extensiveIsNullOrEmpty } from '../../../../utils/helpers';

/**
 * Simple helper function for pooling common state updates to stay DRY.
 * OBS: This function mutates state directly.
 * @param state to perform updates on
 */
export const applyCommonStateUpdates = (
    state: StateMapper<_Pick<ProductDetailsStoreModel, _FilterKeys<ProductDetailsStoreModel, ActionTypes, 'default'>>>
): void => {
    state.availableConfiguration = getAvailableConfigurations({
        allConfigurations: state.allConfigurations,
        carFamily: state.carFamily,
        selectedProduct: state.selectedProduct,
        selectedColor: state.selectedColor,
    });

    if (state.selectedProduct) {
        const { resources, matchFound } = handleCarResources(state.selectedProduct, state.selectedColor);
        state.matchedResources = resources;
        state.colorAndResourceMatched = matchFound;
    }

    if (!state.priceDifferenceAcknowledged) {
        state.priceDifferenceAcknowledged = true;
    }

    state.productUpdateFinished = true;

    validateOwnershipModeAndSelectedTab(state);
};

export const matchEquipmentLine = (equipmentLine: string | null, stateEquipmentline: string | null): boolean => {
    if (
        (!equipmentLine || equipmentLine === null || equipmentLine === undefined || equipmentLine === '') &&
        (!stateEquipmentline || stateEquipmentline === null || stateEquipmentline === undefined || stateEquipmentline === '')
    ) {
        return true;
    } else if (equipmentLine === stateEquipmentline) return true;
    return false;
};

export const matchBattery = (capacity: string, range: number, selectedCapacity: string, selectedRange: number): boolean => {
    if (extensiveIsNullOrEmpty(capacity) && extensiveIsNullOrEmpty(selectedCapacity) && range === 0 && selectedRange === 0) {
        return true;
    }
    if (capacity !== selectedCapacity && range !== selectedRange) return false;
    return true;
};
