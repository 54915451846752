import { generateFormId } from '../../../lib/state/booking-form/booking-form.helpers';

type OnChangeParams = {
    value: string;
    id: string;
    rootId?: string;
};

export type Prefill = {
    email: string | null;
    mobile: string | null;
    licensePlate: string | null;
    fullName: string | null;
    address: string | null;
    zip: string | null;
};

export const getPrefillValuesFromQuery = (): OnChangeParams[] => {
    const urlParams = new URLSearchParams(window.location.search);
    const prefillInfo: Prefill = {
        email: urlParams.get('email'),
        mobile: urlParams.get('mobile'),
        licensePlate: urlParams.get('licensePlate'),
        fullName: urlParams.get('fullName'),
        address: urlParams.get('address'),
        zip: urlParams.get('zip'),
    };

    const anyPrefillData = Object.values(prefillInfo).some((prop) => prop != null);
    if (!anyPrefillData) return [];

    const newValues = [
        { id: generateFormId('YourCar', 'Email'), value: prefillInfo.email },
        { id: generateFormId('YourCar', 'Telephone number'), value: prefillInfo.mobile },
        { id: generateFormId('YourCar', 'Car Registration Number'), value: prefillInfo.licensePlate },
        { id: generateFormId('ContactInfo', 'Name'), value: prefillInfo.fullName },
        { id: generateFormId('ContactInfo', 'Address', 'address'), value: prefillInfo.address, rootId: generateFormId('ContactInfo', 'Address') },
        { id: generateFormId('ContactInfo', 'Address', 'zip'), value: prefillInfo.zip, rootId: generateFormId('ContactInfo', 'Address') },
    ];

    return newValues
        .filter((newValue) => newValue.value != null && newValue.value.length > 0)
        .map((newValue) => ({
            ...newValue,
            value: newValue.value as string,
        }));
};
