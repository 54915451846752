export const Units = {
    km: 'km',
    kWh: 'kWh',
} as const;

export const Abbreviations = {
    MDR: 'mdr.',
    MD: 'md.',
    PR: 'pr.',
    KR: 'kr.',
    KR_SLASH_MD: 'kr./md.',
    KR_SLASH_MDR: 'kr./mdr.',
    KR_SLASH_AAR: 'kr./år',
    KM_SLASH_L: 'km/l.',
    KM_SLASH_T: 'km/t',
    G_SLASH_KM: 'g./km',
    KM_SLASH_MD: 'km/md.',
    KM_SLASH_AAR: 'km/år',
} as const;
